export const locale = {
    lang: 'de',
    data: {
        'basic': {
            'save': 'Speichern',
            'cancel': 'Abbrechen',
            'done': 'Erledigt',
            'edit':  'Bearbeiten',
            'close': 'Schließen',
            "next": "Weiter",
            "version": "Version",
            "env": "Umgebung",
            "search": "Suchen",
            "scan_qr_heading": "QR-Code scannen",
            "scan_qr_description": "Pass den QR-Code in die Kamera ein"
        },
        'menu': {
            'office': 'Büro',
            'office_list': 'Büroliste',
            'issue_reporter': 'Fehlermelder',
            'logout': 'Abmelden'
        },
        'dashboard': {
            'dashboard': 'Dashboard',
            'dashboard_desc': "Einfache und intelligente Lösungen, damit sich die Arbeit lohnt...",
        },
        'issue_reporter': {
            'office_name': 'Büro Name',
            'select_register': 'Register auswählen',
            'register_name': 'Register Name',
            'description': 'Beschreibung',
            'type': 'Typ',
            'issue_reporter': 'Fehlermelder',
            'blocker': 'Blockierer',
            'issue_to_solve': 'Zu lösendes Problem',
            'enhancement': 'Verbesserung',
            'all': 'Alle',
            'favorites': 'Favoriten',
            'search': 'Suchen',
            'subject': 'Betreff'
        },
        'calendar': {
            'subject': 'Betreff',
            'responsible': 'Verantwortlich',
            'start_date': 'Startdatum',
            'end_date': 'Enddatum',
            'type': 'Typ',
            'description': 'Beschreibung',
            'priority': 'Priorität',
            'event': 'Ereignis',
            'alert': 'Warnung',
            'view': 'Ansicht',
            'view_options': 'Ansichtsoptionen',
            'day': 'Tag',
            'day_list': 'Tagesliste',
            'week': 'Woche',
            'week_list': 'Wochenliste',
            'month': 'Monat',
            'month_list': 'Monatsliste',
            'today': 'Heute',
            'ongoing': 'Laufend',
            'mine': 'Meine',
            'all': 'Alle',
            'prev': 'Zurück',
            'next': 'Weiter',
            'title_and_responsible_is_required': 'Titel und Verantwortlicher sind erforderlich.',
            'fetching_data': 'Daten werden abgerufen',
            'you_switched_to_my_calendar': 'Sie haben zu Meinem Kalender gewechselt',
            'you_switched_to_all_calendar': 'Sie haben zu Alle Kalender gewechselt',
        },
        'calendar_add': {
            'title': 'Titel',
            'responsible': 'Verantwortlich',
            'start': 'Startdatum',
            'end': 'Enddatum',
            'done': 'Erledigt',
            'type': 'Typ',
            'description': 'Beschreibung',
            'event': 'Ereignis'
        },
        "record": {
            record_list: "Aufzeichnungsliste",
            no_record_found: "Keine Aufzeichnung gefunden",
            record_edit: "Aufzeichnung bearbeiten",
            no_access: "Kein Zugriff",
            select_filter: "Filter auswählen",
            open_on_map: "Auf Karte öffnen",
            no_address: "Keine Adresse",
            contact_search: "Kontaktsuche",
            reorder_description: "Ziehen und ablegen",
            reorder_list: "Sortierung",
            select_action: "Wählen Sie Aktion",
            cancel: "Stornieren",
            select: "Wählen"
        },
        "auth": {
            "login": "Anmelden",
            "username": "Benutzername",
            "username_req": "Benutzername ist erforderlich",
            "password": "Passwort",
            "password_req": "Passwort ist erforderlich"
        },
        "messages": {
            'offline': "offline",
            'offline_msg': "Sie sind jetzt offline",
            'online': "online",
            "event_updated": "Ereignis aktualisiert",
            "event_created": "Ereignis erstellt",
            "report_success": "Vielen Dank für die Einreichung des Berichts",
            "some_error": "Es ist ein Fehler aufgetreten",
            "no_form_id_error": "Keine FormID gefunden",
            "fill_all_fields": "Füllen Sie alle erforderlichen Felder",
            "saved_success": "Erfolg gerettet",
            "no_reg_or_rec": "Kein Register oder Rücktritt",
            
            "getting_data": "Daten erhalten",
            "fetching_data": "Daten abrufen",
            "uploading_data": "Daten hochladen",
            "loading": "Laden..."
        }
    }
};
