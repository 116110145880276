// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: 'https://api.w4.pinbox24.com/',
  // serverUrl: 'https://api.w3.pinbox24.com/',
  apiKey: "AIzaSyBFsPmLE2vm5ZD48xN7ZWws6MP8SHjM3Ug",
  serverUrl: 'https://v42-stage-test.dev.eat.pl/',
  // serverUrl: 'https://api.w4.pinbox24.com/',
  // serverUrl: 'http://localhost:3001/',
  androidSenderId: '988916405168',
  oneSignalAppId: '193b89fd-4b08-4a89-910c-adee9633799a'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
