export const locale = {
    lang: 'pl',
    data: {
        'basic': {
            'save': 'Zapisz',
            'cancel': 'Cancel',
            'done': 'Załatwione',
            'edit':  'Edytuj',
            'close': 'Zamknij',
            "next": "Dalej",
            "version": "Version",
            "env": "Env",
            "search": "Szukaj",
            "scan_qr_heading": "Scan QR code",
            "scan_qr_description": "Dopasuj do ramek poniżej"
        },
        'menu': {
            'office': 'Biuro',
            'office_list': 'Lista biur',
            'issue_reporter': ' Info Reporter',
            'logout': 'Wyloguj'
        },
        'dashboard': {
            'dashboard': 'Tablica',
            'dashboard_desc': "Inteligentne rozwiązania, aby praca miała sens...",
        },
        'issue_reporter': {
            'office_name': 'Nazwa biura',
            'select_register': 'Wybierz rejestr',
            'register_name': 'Nazwa rejestru',
            'description': 'Opis',
            'type': 'Typ',
            'blocker': 'Bloker',
            'issue_reporter': ' Info Reporter',
            'issue_to_solve': 'Problem do rozwiązania',
            'enhancement': 'Ulepszenie',
            'all': 'Wszystko',
            'favorites': 'Ulubione',
            'search': 'Szukaj',
            'subject': 'Temat'
        },
        'calendar': {
            'subject': 'Temat',
            'responsible': 'Odpowiedzialny',
            'start_date': 'Start',
            'end_date': 'Koniec',
            'type': 'Typ',
            'description': 'Opis',
            'priority': 'Priorytet',
            'event': 'Zdarzenie',
            'alert': 'Powiadomienie',
            'view': 'Widok',
            'view_options': 'Opcje widoku',
            'day': 'Dzień',
            'day_list': 'Dzień lista',
            'week': 'Tydzień',
            'week_list': 'Tydzień lista',
            'month': 'Miesiąc',
            'month_list': 'Miesiąc lista',
            'today': 'Dzisiaj',
            'ongoing': 'W toku',
            'mine': 'Moje',
            'all': 'Wszystkie',
            'prev': 'Poprzednie',
            'next': 'Następne',
            'title_and_responsible_is_required': 'Pole odpowiedzialny i temat są wymagane!',
            'fetching_data': 'Pobieranie danych',
            'you_switched_to_my_calendar': 'Przełączyłeś się na mój kalendarz',
            'you_switched_to_all_calendar': 'Przełączyłeś się na kalendarz wszystkich'
        },
        'calendar_add': {
            'title': 'Tytuł',
            'responsible': 'Odpowiedzialny',
            'start': 'Start',
            'end': 'Koniec',
            'done': 'Zakończ',
            'type': 'Typ',
            'description': 'Opis',
            'event': 'Wydarzenie'
        },
        "record": {
            record_list: "Lista aplikacji",
            no_record_found: "Nie znaleziona nagrania",
            record_edit: "Record Edit",
            select_filter: "Wybierz filtr",
            no_access: "No Access",
            open_on_map: "Open on map",
            no_address: "No Address",
            contact_search: "Wyszukaj Kontakt",
            reorder_description: "Przeciągnij i upuść",
            reorder_list: "Sortowanie",
            select_action: "Wybierz działanie",
            cancel: "Anulować",
            select: "Wybierać"
        },
        "auth": {
            "login": "Zaloguj się",
            "username": "Nazwa użytkownika",
            "username_req": "Nazwa użytkownika jest wymagana",
            "password": "Hasło",
            "password_req": "Hasło jest wymagane"
        },
        "messages": {
            "offline": "Offline",
            "offline_msg": "Jesteś teraz offline",
            "online": "Online",
            "event_updated": "Wydarzenie zaktualizowane",
            "event_created": "Wydarzenie utworzone",
            "report_success": "Dziękujemy za przesłanie raportu",
            "some_error": "Wystąpił błąd",
            "no_form_id_error": "Nie znaleziono ID formularza",
            "fill_all_fields": "Wypełnij wszystkie wymagane pola",
            "saved_success": "Zapisano pomyślnie",
            "no_reg_or_rec": "Brak Rejestru lub ID rekordu",
            
            "getting_data": "Pobieranie danych",
            "fetching_data": "Pobieranie danych",
            "uploading_data": "Przesyłanie danych",
            "loading": "Ładowanie..."
        }
    }
};
