export const locale = {
    lang: 'en',
    data: {
        'basic': {
            'save': 'Save',
            'cancel': 'Cancel',
            'done': 'Done',
            'edit':  'Edit',
            'close': 'Close',
            "next": "Next",
            "version": "Version",
            "env": "Env",
            "search": "Search",
            "scan_qr_heading": "Scan QR code",
            "scan_qr_description": "Fit the qrcode in the camera"
        },
        'menu': {
            'office': 'Office',
            'office_list': 'Office List',
            'issue_reporter': 'Issue Reporter',
            'logout': 'Logout'
        },
        'dashboard': {
            'dashboard': 'Dashboard',
            'dashboard_desc': "Simple and clever solutions, for work to make sense...",
        },
        'issue_reporter': {
            'office_name': 'Office Name',
            'select_register': 'Select Register',
            'register_name': 'Register Name',
            'description': 'Description',
            'type': 'Type',
            'issue_reporter': 'Issue Reporter',
            'blocker': 'Blocker',
            'issue_to_solve': 'Issue To Solve',
            'enhancement': 'Enhancement',
            'all': 'All',
            'favorites': 'Favorites',
            'search': 'Search',
            'subject': 'Subject'
        },
        'calendar': {
            'subject': 'Subject',
            'responsible': 'Responsible',
            'start_date': 'Start Date',
            'end_date': 'End Date',
            'type': 'Type',
            'description': 'Description',
            'priority': 'Priority',
            'event': 'Event',
            'alert': 'Alert',
            'view': 'View',
            'view_options': 'View Options',
            'day': 'Day',
            'day_list': 'Day List',
            'week': 'Week',
            'week_list': 'Week List',
            'month': 'Month',
            'month_list': 'Month List',
            'today': 'Today',
            'ongoing': 'Ongoing',
            'mine': 'Mine',
            'all': 'All',
            'prev': 'Prev',
            'next': 'Next',
            'title_and_responsible_is_required': 'Title and Responsible is required.',
            'fetching_data': 'Fetching Data',
            'you_switched_to_my_calendar': 'You switched to My Calendar',
            'you_switched_to_all_calendar': 'You switched to All Calendar',
        },
        'calendar_add': {
            'title': 'Title',
            'responsible': 'Responsible',
            'start': 'Start Date',
            'end': 'End Date',
            'done': 'Done',
            'type': 'Type',
            'description': 'Description',
            'event': 'Event'
        },
        "record": {
            record_list: "Record List",
            no_record_found: "No Record Found",
            record_edit: "Record Edit",
            no_access: "No Access",
            select_filter: "Select Filter",
            open_on_map: "Open on map",
            no_address: "No Address",
            contact_search: "Contact Search",
            reorder_description: "Drag and drop",
            reorder_list: "Sorting",
            select_action: "Select Action",
            cancel: "Cancel",
            select: "Select"
        },
        "auth": {
            "login": "Login",
            "username": "Username",
            "username_req": "Username is required",
            "password": "Password",
            "password_req": "Password is required",
        },
        "messages": {
            'offline': "Offline",
            'offline_msg': "You are offline now",
            'online': "Online",
            "event_updated": "Event Updated",
            "event_created": "Event Created",
            "report_success": "Thanks for submitting report",
            "some_error": "Some Error occurred",
            "no_form_id_error": "No FormId found",
            "fill_all_fields": "Fill all required fields",
            "saved_success": "Saved Success",
            "no_reg_or_rec": "No Register or recId",
            
            "getting_data": "Getting Data",
            "fetching_data": "Fetching Data",
            "uploading_data": "Uploading Data",
            "loading": "Loading..."
        }
    }
};
