import { Injectable } from '@angular/core';
// import { Events } from '@ionic/angular';
import { AuthProvider } from './auth.provider';
import { HelperProvider } from './helper.provider';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  _favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  LOGIN_TIME = 'loginTime';
  LOGIN_CRED = 'loginCred';
  HOUR_TIME = 60000 * 60;

  constructor(
    // public events: Events,
    private authProvider: AuthProvider,
    private helperProvider: HelperProvider
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this._favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this._favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this._favorites.indexOf(sessionName);
    if (index > -1) {
      this._favorites.splice(index, 1);
    }
  }

  login(username: string) {
    window.localStorage.setItem(this.HAS_LOGGED_IN, true + "")
    this.setUsername(username);
    return this.helperProvider.onEvent.next('user:login');
  }

  signup(username: string) {
    window.localStorage.setItem(this.HAS_LOGGED_IN, true + "")
    this.setUsername(username);
    return this.helperProvider.onEvent.next('user:signup');
  }

  logout() {
    window.localStorage.clear();
    window.localStorage.removeItem(this.HAS_LOGGED_IN)
    window.localStorage.removeItem('username');
    this.helperProvider.onEvent.next('user:logout');
  }

  setUsername(username: string) {
    return window.localStorage.setItem('username', username);
  }

  setLoginTime() {
    window.localStorage.setItem(this.LOGIN_TIME, new Date().getTime() + "")
  }

  getUsername() {
    return window.localStorage.getItem('username')
  }

  isLoggedIn() {

    const token = window.localStorage.getItem('ngStorage-token');
    console.log("token = ", token);
    if (!token) {
      return true;
    }


    const value = window.localStorage.getItem(this.LOGIN_TIME)
    // tslint:disable-next-line:radix
    if (value && parseInt(value) !== NaN) {
      // tslint:disable-next-line:radix
      const remainingTime = (parseInt(value) + (this.HOUR_TIME * 8)) - new Date().getTime();
      if (remainingTime <= (this.HOUR_TIME * 2)) {
        // reauth 
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  reAuth(): Promise<any> {
    return new Promise ((resolve, reject) => {
      if (JSON.parse(window.localStorage.getItem(this.LOGIN_CRED))) {
        this.authProvider.login(JSON.parse(window.localStorage.getItem(this.LOGIN_CRED)))
        .then((data) => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject('UnAuthorized');
      }
    });
  }

  checkHasSeenTutorial() {
    return window.localStorage.getItem(this.HAS_SEEN_TUTORIAL);
  }
}
